import React from 'react'

import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';
import VerificacionCredencial from '../components/pages/VerificacionCredencial';

class VerifocacionCredencialPage extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { pageContext } = this.props
        return (
            <LayoutsComponent
                layout={pageContext.layout}
                broker={pageContext.broker}
                companyName={pageContext.company}
                location={this.props.location}
                pageContext={this.props.pageContext}
                render={() => (
                    <>
                        <SEO title={pageContext.title} />
                        <VerificacionCredencial />
                    </>
                )}
            />
        )
    }
}

export default VerifocacionCredencialPage;
