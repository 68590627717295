import { css } from '@emotion/react'
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { TitleIcon, CardElevation, FileUpload } from '@findep/mf-landings-core'
import { Box, Typography, Grid, Button, Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import CreditCardIcon from '@material-ui/icons/CreditCard';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'

import { VerificacionId } from '../../services/LandingV4/verificacionCredencial.service'
import { navigate } from '../../helpers/queryNavigate';

import { root, styleTitle, text, btnContainer } from '../../styles/documents'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const VerificacionCredencial = () => {

    const [generarError, setGeneralError] = useState(true);
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [errorService, setErrorService] = useState(false)
    const [flujo, setFlujo] = useState('');
    const [state, setState] = useState({ frontPicture: '', backPicture: '' });

    const classes = useStyles();

    useEffect(() => {
        let initialFlujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
        setFlujo(initialFlujo)
    }, [])

    useEffect(() => {
        state.frontPicture && state.backPicture ?
            setGeneralError(false) :
            setGeneralError(true)
    }, [state])

    const rootBtn = css`
        border-radius: 20px;
    `

    const handleFile = async (file, flag) => {
        await setState({ ...state, [flag]: file.value })
    }

    const handleNextStep = async () => {
        setOpenBackDrop(true)
        const verificacion = new VerificacionId(flujo.creditApplicationId)
        verificacion.setPictures(state.frontPicture, state.backPicture)
        try {
            const { status } = await verificacion.send()
            if (status === 200 || status === 204) {
                sessionStorage.setItem('flujo', JSON.stringify({ ...flujo, tubo: { ...flujo.tubo, VerifyIdentityWithAutonomation: 'InProgress' } }))
                navigate('/tu-proceso/')
            }
        } catch (error) {
            setOpenBackDrop(false)
            setErrorService(true)
            //El envio al server fallo
            console.error(error)
        }
    }
    return (
        <Grid container justify="center" >
            <Grid item xs={12} md={6} css={root}  >
                <Grid container css={styleTitle} justify="center">
                    <TitleIcon title="Carga de identificación" icon={CreditCardIcon} textColor={true} />
                </Grid >
                <CardElevation>
                    <Box>
                        <Typography css={text}>
                            Favor de ingresar de forma clara, legible y completa los siguientes documentos:
                        </Typography>
                        <Box css={styleTitle}>
                            <FileUpload
                                id="verificacion-credencial-FrenteDeTuINE-input"
                                accept=".png, .jpeg"
                                icon={ContactMailIcon}
                                help='ayuda'
                                label='Frente de tu INE'
                                helperText='Puedes subir archivos .jpeg o .png'
                                onChange={e => handleFile(e, 'frontPicture')}
                                required
                            />
                        </Box>
                        <FileUpload
                            id="verificacion-credencial-ReversoDeTuINE-input"
                            accept=".png, .jpeg"
                            icon={CreditCardIcon}
                            help='ayuda'
                            label='Reverso de tu INE'
                            helperText='Puedes subir archivos .jpeg o .png'
                            onChange={e => handleFile(e, 'backPicture')}
                            required
                        />
                    </Box>
                    <Box css={btnContainer}>
                        <Button
                            id="verificacion-credencial-nextStep-button"
                            variant="contained"
                            color="primary"
                            startIcon={<TrendingFlatIcon />}
                            css={rootBtn}
                            disabled={generarError}
                            onClick={handleNextStep}
                        >
                            Continuar
                        </Button>
                    </Box>
                </CardElevation>

            </Grid>
            <Backdrop open={openBackDrop} className={classes.backdrop}>
                <CircularProgress color="primary" />
            </Backdrop>
            <Snackbar open={errorService} autoHideDuration={6000} onClose={() => setErrorService(false)}>
                <Alert variant="filled" severity="warning" onClose={() => setErrorService(false)}>
                    Lo sentimos, por el momento el servicio no se encuentra disponible
                        </Alert>
            </Snackbar>
        </Grid>
    );
}

export default VerificacionCredencial;
