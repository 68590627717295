import Service from '../Service'
import { LandingV4Instance } from './Instance'

/**
 * @class
 * @param  {String} landingApplicationId
 */
export class VerificacionId extends Service {
  constructor(creditApplicationId) {
    super()
    this._creditApplicationId = `${creditApplicationId}`
    this._frontPicture = ''
    this._backPicture = ''
  }

  setPictures(frontPicture, backPicture) {
    this._frontPicture = frontPicture
    this._backPicture = backPicture
  }

  get data () {
      // en este caso no se envia un object se envia como multioart/form-data
    const formData = new FormData();
    // en la linea 24 se agrega al multipart el file del signature, el primer parametro es la key del service y el segundo su valor
        formData.append('IdFrontPicture',this._frontPicture)
        formData.append('IdBackPicture',this._backPicture)
        // en la linea 26 se agrega al multipart el credit aplicationId, el primer parametro es la key del service y el segundo su valor
        formData.append('CreditApplicationId',this._creditApplicationId)
        // retornamos ala peticion el multipart creado
    return formData
  }

  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  send() {
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'POST',
      url: '/verifications/identity-autonomation',
      data: self.data
    }))
  }
}
